<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <div class="row justify-content-md-center">
      <div class="col-12">
        <form-wizard
          title=""
          subtitle=""
          color="#918158"
          nextButtonText='Avançar'
        >
          <tab-content :title="$t('taskCampaign.step.general')" :before-change="toStep2" icon="ti-settings"  >
            <form data-vv-scope="step-01">
            <div class="row">
              <!-- Titulo -->
              <div class="col-12 col-md-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-11 mt-4 mb-3">
                        <label>{{$t('taskCampaign.title')}}</label>
                        <custom-input
                          v-model="task.title"
                          name="task.title"
                          type="text"
                          rootClassName="md-form mb-0"
                          inputClassName="md-form-control"
                          v-validate="{ required: true }"
                          :error="errors.first('task.title')">
                        </custom-input>
                      </div>
                      <div class="col-md-1 mt-4">
                        <label>{{$t('taskCampaign.status')}}</label>
                        <custom-switch
                          :listItems="[{text: '', value: 1}]"
                          v-model="task.status"
                          name="task.status"
                          type="checkbox"
                          stateClassName="p-primary"
                          :error="errors.first('task.status')">
                        </custom-switch>
                      </div>

                      <div class="col-sm-12 form-group mb-3">
                        <label>{{ $t('taskCampaign.tipoDisparador')}}</label>
                        <v-select name="task.idTipoDisparador" label="nome"  :clearable="false" :searchable="true"  v-model="task.idTipoDisparador"  :options="tipoDisparadorList" @search="tipoDisparadorFetchOptions" @input="onSelectTipoDisparador"  v-validate="{ required: true }" >
                          <template slot="option" slot-scope="option">
                            <div class="d-center">
                              {{ option.nome }}
                            </div>
                          </template>
                          <template slot="selected-option" slot-scope="option">
                            <div class="selected d-center">
                              {{ option.nome }}
                            </div>
                          </template>
                        </v-select>
                        <transition name="slide" mode="">
                          <small v-if="errors.first('task.idTipoDisparador')" class="royalc-error-field">{{ errors.first('task.idTipoDisparador') }}</small>
                        </transition>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <!-- Descrição -->
              <div class="col-12 col-md-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-12 mt-4">
                        <label>{{$t('taskCampaign.description')}}</label>
                        <custom-textarea
                          v-model="task.description"
                          name="task.description"
                          type="text"
                          rootClassName="md-form mb-0"
                          inputClassName="md-form-control"
                          :rows="3"
                          v-validate="{ required: false }"
                          :error="errors.first('task.description')">
                        </custom-textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Remetente -->
              <div class="col-12 col-md-4">
                <div class="card card-fullheight">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-12 mt-2 mb-4">
                        <label>{{$t('mails.template.sender')}}</label>
                        <v-select label="email" name="task.sender" class="md-form-control"  :clearable="true" :searchable="true" v-model="task.sender"  :options="ccContactList" v-validate="{ required: true }">
                          <template slot="no-options">
                            {{ 'Nenhum resultado encontrado' }}
                          </template>
                          <template slot="option" slot-scope="option">
                            <div class="d-center">
                              {{ option.email }}
                            </div>
                          </template>
                          <template slot="selected-option" slot-scope="option">
                            <div class="selected d-center">
                              {{ option.email }}
                            </div>
                          </template>
                        </v-select>
                        <transition name="slide" mode="">
                          <small v-if="errors.first('task.sender')" class="royalc-error-field">{{ errors.first('task.sender') }}</small>
                        </transition>
                        <div class="font-11">
                        Não esqueça de preencher o CCO, se necessário receber a cópia dos e-mails.<br/>
                        </div>
                      </div>
                      <!-- Default Sender-->
                      <div class="col-md-12 mt-2 mb-4" v-show="task.sender && task.sender.id === 'vendedor'">
                        <label>{{$t('mails.template.defaultSender')}}</label>
                        <v-select label="email" name="task.defaultSender" class="md-form-control"  :clearable="true" :searchable="true" v-model="task.defaultSender"  :options="defaulSenderList" v-validate="{ required: (task.sender && task.sender.id === 'vendedor') }">
                          <template slot="no-options">
                            {{ 'Nenhum resultado encontrado' }}
                          </template>
                          <template slot="option" slot-scope="option">
                            <div class="d-center">
                              {{ option.email }}
                            </div>
                          </template>
                          <template slot="selected-option" slot-scope="option">
                            <div class="selected d-center">
                              {{ option.email }}
                            </div>
                          </template>
                        </v-select>
                        <transition name="slide" mode="">
                          <small v-if="errors.first('task.defaultSender')" class="royalc-error-field">{{ errors.first('task.defaultSender') }}</small>
                        </transition>
                        <div class="font-11">
                          Remetente utilizado quando Cliente:<br/>
                          - Não possuir nenhum vendedor vinculado;<br/>
                          - Vendedor vinculado for igual à "Alan" ou<br/>
                          - Vendedor vinculado estiver inativo.<br/>

                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Com cópia -->
              <div class="col-12 col-md-4">
                <div class="card card-fullheight">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-12 mt-2 mb-4">
                        <label>{{$t('mails.template.cc')}}</label>
                        <multiselect
                          v-model="task.cc"
                          :multiple="true"
                          :max="50"
                          track-by="id"
                          label="name"
                          :allow-empty="true"
                          placeholder="Selecione uma ou mais opções"
                          :close-on-select="false"
                          :clear-on-select="true"
                          :custom-label="mailCustomLabel"
                          :options="ccContactList"
                          :show-labels="false">
                          <template slot="singleLabel" slot-scope="{ option }">
                            <strong>{{ option.email }}</strong>
                          </template>
                          <template slot="option" slot-scope="{ option }">
                            <strong>{{ option.email }}</strong>
                          </template>
                        </multiselect>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Com cópia oculta -->
              <div class="col-12 col-md-4">
                <div class="card card-fullheight">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-12 mt-2 mb-4">
                        <label>{{$t('mails.template.cco')}}</label>
                        <multiselect
                          v-model="task.cco"
                          :multiple="true"
                          :max="50"
                          track-by="id"
                          label="name"
                          :allow-empty="true"
                          placeholder="Selecione uma ou mais opções"
                          :close-on-select="false"
                          :clear-on-select="true"
                          :custom-label="mailCustomLabel"
                          :options="ccContactList"
                          :show-labels="false">
                          <template slot="singleLabel" slot-scope="{ option }">
                            <strong>{{ option.email }}</strong>
                          </template>
                          <template slot="option" slot-scope="{ option }">
                            <strong>{{ option.email }}</strong>
                          </template>
                        </multiselect>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Frequência -->
              <div class="col-6 col-md-6">
                <div class="card card-fullheight">
                  <div class="card-body">
                    <h5 class="box-title mb-3">{{ $t('schedules.frequency') }}</h5>
                      <div class="row">
                        <div class="col-sm-3 form-group mb-3">
                          <label>Enviar a cada</label>
                          <input name="task.taskFrequency.period" class="md-form-control" v-model="task.taskFrequency.period"  type="number"  placeholder="1"
                                 v-validate="{ required: true, min_value: 1 }"
                                 :error="errors.first('task.taskFrequency.period')" >
                          <transition name="slide" mode="">
                            <small v-if="errors.first('task.taskFrequency.period')" class="royalc-error-field">{{ errors.first('task.taskFrequency.period') }}</small>
                          </transition>

                        </div>
                        <div class="col-sm-6 form-group mb-6">
                          <label>&nbsp;</label>
                          <v-select label="name"  :clearable="false" :searchable="false" v-model="task.taskFrequency.type"  :options="recurrenceList" @input="onSelectRecurrence"  >
                            <template slot="option" slot-scope="option">
                              <div class="d-center">
                                {{ option.name }}
                              </div>
                            </template>
                            <template slot="selected-option" slot-scope="option">
                              <div class="selected d-center">
                                {{ option.name }}
                              </div>
                            </template>
                          </v-select>
                        </div>
                        <div  v-show="showBeginAtConfig" class="col-sm-3 form-group mb-3">
                          <label>às</label>
                          <flat-pickr :config="configs.timePicker" v-model="task.taskFrequency.beginTime" class="md-form-control"></flat-pickr>
                        </div>
                        <div v-if="showWeekdayConfig"  class="col-sm-12 form-group mb-12">
                          <label>Repetir</label>
                          <multiple-toggle :options="weekDayList"></multiple-toggle>
                          <transition name="slide" mode="">
                            <small v-if="!validWeekDay" class="royalc-error-field">{{ 'Você deve selecionar pelo menos um dia da semana.' }}</small>
                          </transition>
                        </div>
                        <div v-if="showMonthdayConfig"  class="col-sm-12 form-group mb-12">
                          <label>No Dia</label>
                          <input name="task.taskFrequency.monthday" class="md-form-control" v-model="task.taskFrequency.monthday"  type="text"
                                 v-validate="{ required: true, min_value: 1, max_value: 31 }"
                                 :error="errors.first('task.taskFrequency.monthday')" >
                          <transition name="slide" mode="">
                            <small v-if="errors.first('task.taskFrequency.monthday')" class="royalc-error-field">{{ errors.first('task.taskFrequency.monthday') }}</small>
                          </transition>
                        </div>

                        <div   class="col-sm-6 form-group mb-12">
                          <label>Termina:</label>
                          <v-select label="name"  :clearable="false" :searchable="false" v-model="task.taskFrequency.expiresType"  :options="expiresTypeList" @input="onSelectExpiresType">
                            <template slot="option" slot-scope="option">
                              <div class="d-center">
                                {{ $t('schedules.frequencyType.' + option.name) }}
                              </div>
                            </template>
                            <template slot="selected-option" slot-scope="option">
                              <div class="selected d-center">
                                {{ $t('schedules.frequencyType.' + option.name) }}
                              </div>
                            </template>
                          </v-select>
                        </div>
                        <div v-show="showExpiresInConfig" class="col-sm-3 form-group mb-3">
                          <label v-html="'&nbsp;'"></label>
                          <input name="task.taskFrequency.expiresIn" class="md-form-control" v-model="task.taskFrequency.expiresIn"  type="number"
                                 v-validate="{ required: showExpiresInConfig, min_value: 1 }"
                                 :error="errors.first('task.taskFrequency.expiresIn')" >
                          <transition name="slide" mode="">
                            <small v-if="errors.first('task.taskFrequency.expiresIn')" class="royalc-error-field">{{ errors.first('task.taskFrequency.expiresIn') }}</small>
                          </transition>
                        </div>
                        <div v-show="showExpiresInConfig" class="col-sm-3 form-group mb-3">
                          <label>&nbsp;</label>
                          <div class="md-form">Envios</div>
                        </div>
                        <div v-show="showExpiresAtConfig" class="col-sm-6 form-group mb-3">
                          <label> </label>
                          <div class="input-group-icon input-group-icon-right mb-4">
                            <div class="md-form mb-4">
                              <span class="input-icon input-icon-right"><i class="ti-calendar"></i></span>
                              <flat-pickr name="task.taskFrequency.expiresAt" :config="configs.datePicker" v-model="task.taskFrequency.expiresAt" class="md-form-control" v-validate="{ required: showExpiresAtConfig }"></flat-pickr>
                            </div>
                          </div>
                          <transition name="slide" mode="">
                            <small v-if="errors.first('task.taskFrequency.expiresAt')" class="royalc-error-field">{{ errors.first('task.taskFrequency.expiresAt') }}</small>
                          </transition>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            </form>
          </tab-content>
          <tab-content :title="$t('taskCampaign.step.select recipients')"  :before-change="toStep3" icon="ti-user">
            <form data-vv-scope="step-02">
            <div class="row">
              <!-- Regras de seleção de contatos -->
              <div class="col-12 col-md-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-12 mt-4 mb-5">
                        <label>{{$t('taskCampaign.select contact rules')}}</label>
                        <!-- Novo -->
                        <div class="row">
                          <div class="col-12 accordion no-padding">
                            <div v-for="(group, index) in variableGroupsList" v-bind:key="index">
                              <div class="accordion-header" :class="{ active: group.isActive }" v-on:click="groupToggle(group)">
                                {{ (group) ? group.nome : 'Geral' }}
                                <label  v-if="group && group.descricao" >
                                  <span><i class="ft-alert-circle"></i>
                                    <md-tooltip md-direction="right" v-html="group.descricao"></md-tooltip>
                                  </span>
                                </label>
                                <span class="pull-right">
                                    <i class="fa fa-plus" v-if="!group.isActive"></i>
                                    <i class="fa fa-minus" v-else></i>
                                </span>
                              </div>
                              <div class="accordion-content" v-show="group.isActive">
                                <div class="row">
                                  <div class="col-md-12 mt-4" v-for="item in getOptionsFiltersByGroup((group) ? group.id : null)" :key="item.id" >
                                    <label>{{$t('taskCampaign.fields.'+item.fieldA.nome)}}</label>
                                    <multiselect
                                      v-model="item.value"
                                      :id="item.fieldA.codigo"
                                      key="id"
                                      :multiple="true"
                                      :max="1000"
                                      track-by="nome"
                                      label="nome"
                                      :allow-empty="true"
                                      placeholder="Selecione um ou mais"
                                      :loading="isLoadingSearch"
                                      :close-on-select="false"
                                      :clear-on-select="true"
                                      :custom-label="customLabel"
                                      :options="item.options"
                                      :show-labels="false"
                                      @search-change="asyncFind"
                                      @input="onInput"
                                    >
                                      <template slot="noOptions">
                                        Digite para pesquisar
                                      </template>
                                      <template slot="noResult">
                                        Ops! Nenhum resultado encontrado
                                      </template>
                                    </multiselect>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- FIM  Novo -->
                        <div class="row">
                          <div class="col-md-12 no-padding mt-4">
                            <label>{{$t('taskCampaign.when')}}</label>
                            <div class="col-md-12 no-margim no-padding" v-for="(item, index) in task.filters" :key="item.id" >
                              <div class="row" v-if="isFieldFilter(item)">
                                <div class="col-md-11 pl-0">
                                  <field-filter  v-model="task.filters[index]" :fields="getFieldFilterVariableList" ></field-filter>
                                </div>
                                <div class="col-md-1 pl-0 pr-0">
                                  <a class="btn btn-link btn-sm" @click="removeFilter(index)" tabindex="">
                                    <i class="text-primary  ti-trash font-20"></i>
                                  </a>
                                  <a class="btn btn-link btn-sm" @click="addFilter(index)" tabindex="">
                                    <i class="text-primary fa fa-plus-circle font-20"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Clientes e contatos -->
              <div class="col-6 col-md-6">
                <div class="card card-fullheight">
                  <div class="card-body">
                    <h5 class="box-title mb-3">{{ $t('taskCampaign.customer') }}</h5>
                    <advanced-multiple-select
                      id-field="id"
                      labelField="name"
                      :no-options="'Buscar Cliente'"
                      :tableFields="customerFields"
                      :enableStatus="1"
                      :value="task.customers"
                      :options="customerList"
                      v-bind:fetchOptions="customerFetchOptions"
                      @onRemoved="customerRemoved"
                      @onInput="customerSelected"
                      @rowClicked="customerSelected">
                    </advanced-multiple-select>
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-6">
                <div class="card card-fullheight">
                  <div class="card-body">
                    <h5 class="box-title mb-3">{{ $t('taskCampaign.contacts') }}</h5>
                    <advanced-multiple-select
                      id-field="id"
                      labelField="nome"
                      :no-options="'Buscar Contato'"
                      :tableFields="contactFields"
                      :enableFilter="false"
                      :value="contactList"
                      :options="contactList"
                      @toggleStatus="toggleContactStatus"
                      @toggleAll="toggleContactAllStatus">
                    </advanced-multiple-select>
                  </div>
                </div>
              </div>

              <!-- Arquivo CSV -->
              <div class="col-12 col-md-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-11 mt-4">
                        <label>{{$t('taskCampaign.csv file')}} <i title="Modelo do arquivo CSV. Clique para fazer o download." v-on:click="handleDownloadExample" class="fa fa-question-circle"></i></label>
                        <custom-file-upload
                          v-model="task.files"
                          name="attach"
                          :maxFiles="1"
                          acceptedFiles="text/csv,.csv"
                          rootClassName="md-form mb-0"
                          inputClassName="md-form-control"
                          v-validate="{ required: false }"
                          :uploadSuccess="onFileUploaded"
                          :preview=true
                          :error="errors.first('template.attach')">
                        </custom-file-upload>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </form>
          </tab-content>
          <tab-content :title="$t('taskCampaign.step.message')" :before-change="toStep4" icon="ti-email"  >
            <form data-vv-scope="step-03">
              <!-- Group Mails-->
              <div class="row">
                <div class="col-12 col-md-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-12 mt-4">
                          <div class="col-sm-11">
                            <label>{{ $t('taskCampaign.groupMails')}}</label>
                          </div>
                          <custom-switch
                            :listItems="[{ value: 1}]"
                            v-model="task.groupMails"
                            name="task.groupMails"
                            type="checkbox"
                            stateClassName="p-primary"
                            rootClassName="col-sm-12 mt-0 mb-6"
                            :error="errors.first('task.groupMails')">
                          </custom-switch>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                <!-- Template -->
                <div class="col-12 col-md-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="row">
                        <!-- Template -->
                        <div class="col-md-12 mt-4 mb-3">
                          <label>{{ $t('taskCampaign.template')}}</label>
                          <div class="font-11">
                            Você pode adicionar uma sequência de templates, para que o conteúdo seja diferente a cada envio.
                          </div>
                        </div>
                        <draggable class="col-12" v-model="task.templates" group="templates" @start="drag=true" @end="drag=false">
                          <div class="row sortable" v-for="(template, index) in task.templates" v-bind:key="index">
                            <div class="col-12 p-0 mb-1">
                              {{ index + 1 }}° Disparo
                            </div>
                            <!-- Template -->
                            <div class="col-md-11 pl-0">
                              <v-select name="task.idTemplate" label="name"  :clearable="false" :searchable="true"  v-model="template.idTemplate"  :options="templateList" @search="templateFetchOptions" @input="onSelectTemplate"  v-validate="{ required: true }" >
                                <template slot="option" slot-scope="option">
                                  <div class="d-center">
                                    {{ option.name }}
                                  </div>
                                </template>
                                <template slot="selected-option" slot-scope="option">
                                  <div class="selected d-center">
                                    {{ option.name }}
                                  </div>
                                </template>
                              </v-select>
                              <transition name="slide" mode="">
                                <small v-if="errors.first('task.idTemplate')" class="royalc-error-field">{{ errors.first('task.idTemplate') }}</small>
                              </transition>
                            </div>

                            <!-- Add New Template-->
                            <div class="col-sm-1 form-group no-margin pl-0">
                                <div class="sort-icon">
                                  <i class="fa fa-sort"></i>
                                </div>
                                <i v-if="(template.idTemplate !== null)" title="Clique para editar o template." v-on:click="editTemplate(index, template.idTemplate.id_template)" class="text-primary font-26 fa fa-edit "></i>
                                <i title="Clique para adicionar um novo template." v-on:click="addNewTemplate(index)" class="text-primary font-26 fa fa-plus-circle "></i>
                            </div>
                            <a v-show="task.templates.length > 1" class="btn-link sortable-remove" v-on:click="removeSequenciaTemplate(index)">Remover template da sequência de envios</a>

                          </div>
                        </draggable>
                        <div class="col-12 mt-4">
                          <a v-on:click="addSequenciaTemplate" class="pull-right btn  btn-outline-light">Adicionar novo template na sequência de envio</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </form>
            <div class="row">
                <!-- Conteúdo da mensagem -->
                <div class="col-12 col-md-12 template-content-edit" v-show="showTemplateContent">
                  <new-template-modal :csv-fields="csvFieldsTable" :index="templateNewEditIndex" :template="templateNewEdit" @onCancel="showTemplateContent = false" @onSubmit="onTemplateCreated" @onEdit="onTemplateUpdated" />
                </div>
            </div>
          </tab-content>
          <tab-content :title="$t('taskCampaign.step.confirm')"  icon="ti-check">
            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-6 mt-4">
                        <label>{{$t('taskCampaign.title')}}</label>
                        <p class="text-body ">{{ task.title }}</p>
                      </div>
                      <div class="col-md-6 mt-4">
                        <label>{{$t('taskCampaign.description')}}</label>
                        <p class="text-body ">{{ task.description }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Contatos selecionados -->
              <div class="col-12 col-md-12">
                <div class="card">
                  <div class="card-body">
                    <custom-vue-table
                      label="taskCampaign.select contact rules"
                      ref="contactsTable"
                      http-method="post"
                      :api-url="loadContactEndpoint"
                      :fields="fieldsTable"
                      :filter-fields="previewContactsfilterFields"
                      :sort-order="sortOrder"
                      totalLabel="contatos"
                      :append-params="activeFilters"
                      :row-class="rowClass"
                      track-by="Contato_id"
                    >
                      <template slot="actions" slot-scope="props">
                        <div class="custom-actions">
                          <span v-if="props.rowData.hasOwnProperty('Restricao_Envio') && props.rowData.Restricao_Envio">
                            Restrição de envio
                          </span>
                          <button v-else title="Verificar" class="btn btn-link"
                                  @click="showContactData(props.rowData, props.rowIndex)">
                            <i class="text-primary  ft-user-check font-20"></i>
                          </button>
                        </div>
                      </template>
                    </custom-vue-table>
                  </div>
                </div>
              </div>
              <!-- Contatos CSV -->
              <div v-show="task.files.length > 0" class="col-12 col-md-12">
                <div class="card">
                  <div class="card-body">
                    <custom-vue-table
                      label="taskCampaign.csv file"
                      :filter-fields="previewContactsCsvfilterFields"
                      ref="contactsCsvTable"
                      :api-url="loadContactCsvEndpoint"
                      :fields="csvFieldsTable"
                      totalLabel="contatos"
                      :append-params="{ file: task.files[0] }"
                      track-by="Contato_email"
                    >
                      <template slot="actions" slot-scope="props">
                        <div class="custom-actions">
                          <button title="Verificar" class="btn btn-link"
                                  @click="showContactData(props.rowData, props.rowIndex)">
                            <i class="text-primary  ft-user-check font-20"></i>
                          </button>
                        </div>
                      </template>
                    </custom-vue-table>
                  </div>
                </div>
              </div>
              <!-- Contatos duplicados -->
              <div class="col-12 col-md-12">
                <div class="card">
                  <div class="card-body">
                    <custom-vue-table
                      label="taskCampaign.select duplicated contact rules"
                      :description="'Lista de contatos duplicados, ou seja, que receberão o e-mail mais de uma vez.<br/>Para cancelar o envio, basta selecionar a opção Cancelar Envio '"
                      ref="contactsDuplicatedTable"
                      http-method="post"
                      :api-url="loadDuplicatedContactEndpoint"
                      :fields="fieldsDuplicatedTable"
                      :filter-fields="previewDuplicatedContactsfilterFields"
                      :sort-order="sortOrderDuplicates"
                      totalLabel="contatos"
                      :append-params="activeFilters"
                      :row-class="rowClass"
                      track-by="Contato_id"
                    >
                      <template slot="selected" slot-scope="props">
                        <div class="custom-actions">
                          <input type="checkbox" v-model="task.cancelSendList" :value="{ Contato_id: props.rowData.Contato_id }">
                        </div>
                      </template>
                      <template slot="actions" slot-scope="props">
                        <div class="custom-actions">
                          <span v-if="props.rowData.hasOwnProperty('Restricao_Envio') && props.rowData.Restricao_Envio">
                            Restrição de envio
                          </span>
                          <button v-else title="Verificar" class="btn btn-link"
                                  @click="showContactData(props.rowData, props.rowIndex)">
                            <i class="text-primary  ft-user-check font-20"></i>
                          </button>
                        </div>
                      </template>
                    </custom-vue-table>
                  </div>
                </div>
              </div>

            </div>
          </tab-content>
          <!-- Action Buttons -->
          <template slot="footer" slot-scope="props">
            <div class="wizard-footer-left">
            </div>
            <div class="wizard-footer-right">
              <button  v-if="props.activeTabIndex > 0" v-show="props.activeTabIndex !==2 || !showTemplateContent"  v-on:click="props.prevTab()"  class="btn  btn-outline-light">
                <i class="text-primary fa fa-angle-double-left font-20"></i> Voltar
              </button>
              <button v-if="!props.isLastStep" v-show="props.activeTabIndex !==2 || !showTemplateContent" v-on:click="props.nextTab()" class="btn  btn-outline-light" href="javascript:;">
                Avançar <i class="text-primary fa fa-angle-double-right font-20"></i>
              </button>
              <button v-else v-on:click="onSave()" class="btn  btn-outline-light">
                {{props.isLastStep ? 'Salvar' : 'Próximo'}}
              </button>
            </div>
          </template>
        </form-wizard>
      </div>

    </div>
  </div>
</template>

<script>
import Vue from 'vue'
// Modal
import VModal from 'vue-js-modal'
import 'vue-js-modal/dist/styles.css'

// VueDraggable
import draggable from 'vuedraggable'

// Validator
import VeeValidate from 'vee-validate'
import { locale } from '@/utils/validator'

import dayjs from 'dayjs'

import CustomVueTable from '@/components/Table/CustomVueTable'

// Loading
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
// Components
import AdvancedMultipleSelect from '@/components/Forms/AdvancedMultipleSelect.vue'

// eslint-disable-next-line
import { util } from '@/utils/utils.js'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

// Form
import CustomInput from '@/components/Forms/CustomInput.vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import CustomTextarea from '@/components/Forms/CustomTextarea.vue'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import Portuguese from '@/i18n/flatpickr/pt.js'

import MultipleToggle from '@/components/Forms/MultipleToggle.vue'
import CustomSwitch from '@/components/Forms/CustomSwitch.vue'

import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import FieldFilter from './FieldFilter'
import CustomFileUpload from '@/components/Forms/CustomFileUpload.vue'

// Services
import CustomerService from '@/services/CustomerService'
import UserService from '@/services/UserService'
import OriginDestinationService from '@/services/OriginDestinationService'
import TaskService from '@/services/TaskService'
import CommoditieService from '@/services/CommoditieService'
import TransportCompanyService from '@/services/TransportCompanyService'
import MailService from '@/services/MailService'
import FileService from '@/services/FileService'
import TaskCampaignTypeService from '@/services/TaskCampaignTypeService'

// Modals
import NewTemplateModal from './NewTemplateModal'
import MessagePreviewModal from './MessagePreviewModal'

VeeValidate.Validator.localize({ 'pt_BR': locale })
Vue.use(VeeValidate, { locale: 'pt_BR', fieldsBagName: 'formFields' })
Vue.use(VModal, { dynamic: true, dynamicDefaults: { clickToClose: false } })

export default {
  name: 'ProcessList',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('schedules.title') + ' ' + this.$i18n.t('sidebar.tariffs') + ' - %s',
      breadcrumbs: [
        // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },

      ]
    }
  },
  data () {
    return {
      previewContactsfilterFields: [
        {
          name: '"Cliente_Nome"',
          title: 'Cliente',
          type: 'text',
          value: null
        },
        {
          name: '"Contato_Nome"',
          title: 'Nome',
          type: 'text',
          value: null
        },
        {
          name: '"Contato_email"',
          title: 'E-mail',
          type: 'text',
          value: null
        }
      ],
      previewDuplicatedContactsfilterFields: [
        {
          name: '"Cliente_Nome"',
          title: 'Cliente',
          type: 'text',
          value: null
        },
        {
          name: '"Contato_Nome"',
          title: 'Nome',
          type: 'text',
          value: null
        },
        {
          name: '"Contato_email"',
          title: 'E-mail',
          type: 'text',
          value: null
        }
      ],
      previewContactsCsvfilterFields: [
        {
          name: 'Contato_email',
          title: 'E-mail',
          type: 'text',
          value: null
        }
      ],
      showTemplateContent: false,
      dayParse: 'DD/MM/YYYY',
      // Begin Customer and contacts
      modalIdTemplate: null,
      activeCustomer: null,
      ccContactList: [],
      contactList: [],
      // End Customer and contacts
      configs: {
        timePicker: {
          wrap: true,
          enableTime: true,
          enableSeconds: false,
          noCalendar: true,
          time_24hr: true
        },
        datePicker: {
          wrap: true,
          enableTime: false,
          dateFormat: 'd/m/Y',
          minDate: 'today',
          locale: Portuguese // locale for this instance only
        }
      },
      loadContactEndpoint: process.env.VUE_APP_APIENDPOINT + 'v2/mail/recipients',
      loadDuplicatedContactEndpoint: process.env.VUE_APP_APIENDPOINT + 'v2/mail/recipients-duplicated',
      loadContactCsvEndpoint: process.env.VUE_APP_APIENDPOINT + 'v2/task/campaign/recipients/csv',
      sortOrder: [
        { field: 'Cliente_Nome', direction: 'asc' },
        { field: 'Contato_email', direction: 'asc' }
      ],
      sortOrderDuplicates: [
        { field: 'Contato_email', direction: 'asc' },
        { field: 'Cliente_Nome', direction: 'asc' }
      ],
      // End Table
      isLoading: true,
      isLoadingSearch: false,
      fullPage: true,
      processEndpoint: process.env.VUE_APP_APIENDPOINT + 'v2/task',
      // List of options to select on select input
      variableList: [],
      variableGroupsList: [],
      customerList: [],
      employeeList: [],
      originsDestinationsList: [],
      commoditiesList: [],
      transportCompanyList: [],
      weekDayList: [
        {
          'id': 1,
          'name': 'D',
          'title': 'Domingo',
          'active': false
        },
        {
          'id': 2,
          'name': 'S',
          'title': 'Segunda-feira',
          'active': false
        },
        {
          'id': 3,
          'name': 'T',
          'title': 'Terça-feira',
          'active': false
        },
        {
          'id': 4,
          'name': 'Q',
          'title': 'Quarta-feira',
          'active': false
        },
        {
          'id': 5,
          'name': 'Q',
          'title': 'Quinta-feira',
          'active': false
        },
        {
          'id': 6,
          'name': 'S',
          'title': 'Sexta-feira',
          'active': false
        },
        {
          'id': 7,
          'name': 'S',
          'title': 'Sábado',
          'active': false
        }
      ],
      equipmentList: [],
      tierList: [],
      strategyList: [],
      currencyList: [],
      recurrenceList: [],
      expiresTypeList: [
        { id: 1, name: 'never' },
        { id: 2, name: 'at' },
        { id: 3, name: 'in' }
      ],
      templateList: [],
      tipoDisparadorList: [],
      //  Selected Values (Form)
      templateNewEditIndex: null,
      templateNewEdit: {
        name: null,
        subject: null,
        files: [],
        content_html: '<p style="font-family:\'Helvetica Neue\',Helvetica,Arial,sans-serif;font-size:16px;line-height:24px;margin-bottom:16px;margin-top:16px;"></p>',
        showSignature: 1
      },
      task: {
        cc: [],
        cco: [],
        sender: [],
        customers: [],
        contacts: [],
        cancelSendList: [],
        files: [],
        title: null,
        description: null,
        templates: [
          {
            idTemplate: null
          }
        ],
        status: 1,
        groupMails: 1,
        filters: [],
        taskFrequency: {
          period: 1,
          beginTime: null,
          type: null,
          weekdays: [],
          expiresIn: null,
          expiresAt: null,
          expiresType: { id: 1, name: 'never' }
        },
        idTipoDisparador: null
      },
      // Configuration to mount table of selected values
      customerFields: [
        {
          name: 'id',
          title: '#',
          sortField: 'id',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'name',
          title: this.$i18n.t('form.customer.name'),
          sortField: 'name',
          width: '90%',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'cnpj',
          title: this.$i18n.t('form.customer.cpfCnpj'),
          width: '90%',
          formatter: (value) => this.$util.formatEmpty(value)
        }
      ],
      // Configuration to mount table of selected values
      contactFields: [
        {
          name: 'idpessoa_contato',
          title: '#',
          sortField: 'idpessoa_contato',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'nome',
          title: this.$i18n.t('origin_destination.name'),
          sortField: 'nome',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'email',
          title: this.$i18n.t('origin_destination.email'),
          sortField: 'email',
          formatter: (value) => this.$util.formatEmpty(value)
        }
      ],
      equipmentFields: [
        {
          name: 'description',
          title: this.$i18n.t('equipment.description'),
          sortField: 'description',

          formatter: (value) => this.$util.formatEmpty(value)
        }
      ]
    }
  },
  components: {
    draggable,
    NewTemplateModal,
    FieldFilter,
    CustomInput,
    Loading,
    MultipleToggle,
    CustomTextarea,
    vSelect,
    CustomSwitch,
    FormWizard,
    TabContent,
    Multiselect,
    flatPickr,
    CustomFileUpload,
    CustomVueTable,
    AdvancedMultipleSelect
  },
  // Apply filters to local filter
  beforeMount () {

  },
  mounted () {
    // this.$store.dispatch('SetShowFilter', true)
    // this.$store.dispatch('setShowSearch', false)
  },
  created () {
    let _this = this
    _this.isLoading = true
    // Load basilares data
    TaskService.getTaskRecurrenceList().then(res => {
      _this.recurrenceList = res.data.data.filter(function (item) {
        return item.id !== 3
      })
      // Set Default
      _this.task.taskFrequency.type = _this.recurrenceList[1]
      TaskCampaignTypeService.getTaskCampaignTypeList().then(res => {
        _this.tipoDisparadorList = res.data.data
        _this.task.idTipoDisparador = _this.tipoDisparadorList[1]

        MailService.getTemplates().then(res => {
          _this.templateList = res.data.data
          MailService.getContacts({}, 1, 1000).then(res => {
            _this.ccContactList = res.data.data
            MailService.getVariables().then(res => {
              _this.variableList = res.data.data
              _this.variableGroupsList = res.data.groups

              // Remove on Dev
              for (let index in _this.variableList) {
                let fieldA = _this.variableList[index]

                if (fieldA.tipo === 4 || fieldA.tipo === 5 || fieldA.tipo === 6 || fieldA.tipo === 8) {
                  let filter = {}
                  filter.fieldA = fieldA
                  filter.value = []
                  filter.options = []
                  _this.task.filters.push(filter)
                }
              }
              // END Remove on Dev

              let taskId = this.$route.params.id_schedule | this.$route.query.id_schedule

              if (taskId) {
                TaskService.getTaskCampaign(taskId).then(res => {
                  // title
                  _this.task.title = res.data.title

                  _this.task.contacts = res.data.contacts
                  _this.task.customers = res.data.customers
                  _this.task.cancelSendList = res.data.cancelSendList

                  // Description
                  _this.task.description = res.data.description

                  _this.task.cc = res.data.cc
                  _this.task.cco = res.data.cco
                  _this.task.sender = res.data.sender
                  _this.task.defaultSender = res.data.defaultSender

                  // Status
                  if (res.data.status === 1) {
                    _this.task.status = 1
                  } else {
                    _this.task.status = null
                  }

                  // GroupMails
                  if (res.data.groupMails === 1) {
                    _this.task.groupMails = 1
                  } else {
                    _this.task.groupMails = null
                  }

                  // Frequency
                  if (res.data.taskFrequency.beginTime) {
                    _this.task.taskFrequency.beginTime = dayjs(res.data.taskFrequency.beginTime).format('HH:mm')
                  }
                  _this.task.taskFrequency.period = res.data.taskFrequency.period
                  _this.task.taskFrequency.type = _this.recurrenceList.find((x) => {
                    return x.id === res.data.taskFrequency.type
                  })

                  _this.task.taskFrequency.expiresType = res.data.taskFrequency.expiresType

                  if (res.data.taskFrequency.expiresAt) {
                    _this.task.taskFrequency.expiresAt = dayjs(res.data.taskFrequency.expiresAt).format(_this.dayParse)
                  }

                  _this.task.taskFrequency.expiresIn = res.data.taskFrequency.expiresIn

                  _this.task.taskFrequency.monthday = res.data.taskFrequency.monthday
                  if (_this.task.taskFrequency.type.id === 2) {
                    let selectedWeekDays = res.data.taskFrequency.weekdays.split(',')

                    for (var index in selectedWeekDays) {
                      let weekDay = parseInt(selectedWeekDays[index])

                      for (var j in _this.weekDayList) {
                        let item = this.weekDayList[j]
                        if (item.id === weekDay) {
                          item.active = true
                          this.$set(this.weekDayList, j, item)
                          break
                        }
                      }
                    }
                  }

                  // Files
                  _this.task.files = res.data.files

                  // Templare
                  _this.task.templates = res.data.templates

                  // Tipo Disparador
                  _this.task.idTipoDisparador = res.data.idTipoDisparador

                  // CC
                  _this.task.cc = res.data.cc
                  // Filters

                  // Options filters
                  for (let filterIndex in res.data.filters) {
                    let filter = res.data.filters[filterIndex]
                    if (filter.fieldA.tipo === 4 || filter.fieldA.tipo === 5 || filter.fieldA.tipo === 6 || filter.fieldA.tipo === 8) {
                      let optionFilter = _this.task.filters.find(function (item) {
                        return item.fieldA.codigo === filter.fieldA.codigo
                      })
                      optionFilter.value = filter.value
                    }
                  }

                  // Field Filters
                  for (let filterIndex in res.data.filters) {
                    let filter = res.data.filters[filterIndex]
                    if (filter.value && filter.value[0].value !== null) {
                      filter.value = filter.value[0].value
                    }

                    if (filter.fieldA.tipo !== 4 && filter.fieldA.tipo !== 5 && filter.fieldA.tipo !== 6 && filter.fieldA.tipo !== 8) {
                      _this.task.filters.push(filter)
                    }
                  }

                  // If any filter by field add a empty
                  if (_this.task.filters.length === _this.getOptionsFilters.length) {
                    _this.addFilter(_this.task.filters.length)
                  }
                  _this.isLoading = false
                }).catch(() => {
                  _this.isLoading = false
                })
              } else {
                _this.addFilter(_this.task.filters.length)
                _this.isLoading = false
              }
            })
          })
        })
      })
    })
  },
  computed: {
    validWeekDay () {
      if (this.showWeekdayConfig) {
        let selected = this.weekDayList.filter((el) => {
          return el.active === true
        })

        return (selected.length > 0)
      }

      return true
    },
    defaulSenderList () {
      let ccList = this.ccContactList.filter((el) => {
        return el.id !== 'vendedor'
      })

      return ccList
    },
    activeFilters () {
      return {
        filters: this.getActiveFilters(false),
        tipo_disparador: (this.task.idTipoDisparador) ? this.task.idTipoDisparador.id : null,
        contacts: this.task.contacts,
        verificar_cadencia: (this.task.idTipoDisparador) ? this.task.idTipoDisparador.verificar_cadencia : true
      }
    },
    fieldsDuplicatedTable () {
      return [
        {
          name: 'selected',
          title: 'Cancelar Envio',
          width: '85px',
          dataClass: 'text-center',
          titleClass: 'text-center'
        },
        {
          name: 'Cliente_Nome',
          title: this.$i18n.t('form.taskSend.customer_name'),
          sortField: 'Cliente_Nome',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'Contato_Nome',
          title: this.$i18n.t('form.taskSend.contact_name'),
          sortField: 'Contato_Nome',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'Contato_email',
          title: this.$i18n.t('form.taskSend.contact_email'),
          sortField: 'Contato_email',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'actions',
          title: 'Ações',
          dataClass: 'text-center wrap-actions',
          titleClass: 'text-center'
        }
      ]
    },
    fieldsTable () {
      return [
        {
          name: 'Cliente_Nome',
          title: this.$i18n.t('form.taskSend.customer_name'),
          sortField: 'Cliente_Nome',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'Contato_Nome',
          title: this.$i18n.t('form.taskSend.contact_name'),
          sortField: 'Contato_Nome',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'Contato_email',
          title: this.$i18n.t('form.taskSend.contact_email'),
          sortField: 'Contato_email',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'actions',
          title: 'Ações',
          dataClass: 'text-center wrap-actions',
          titleClass: 'text-center'
        }
      ]
    },
    csvFieldsTable () {
      let tableFields = []

      if (this.task.files && this.task.files.length > 0 && this.task.files[0].type === 'text/csv') {
        let columns = (this.task.files[0].info.columns).split(',')

        columns.forEach((column) => {
          tableFields.push({
            name: column,
            title: column
          })
        })
      } else {
        tableFields.push({
          name: 'Contato_email',
          title: this.$i18n.t('form.taskSend.contact_email')
        })
      }

      tableFields.push({
        name: 'actions',
        title: 'Ações',
        dataClass: 'text-center wrap-actions',
        titleClass: 'text-center'
      })

      return tableFields
    },
    getOptionsFilters () {
      let _this = this
      let optionsFilters = _this.task.filters.filter(function (item) {
        return item.fieldA.tipo === 4 || item.fieldA.tipo === 5 || item.fieldA.tipo === 6 || item.fieldA.tipo === 8
      })

      return optionsFilters
    },
    getFieldFilterVariableList () {
      let _this = this

      let fields = _this.variableList.filter((field) => {
        return field.tipo !== 4 && field.tipo !== 5 && field.tipo !== 6
      })

      // Ordenando campos por ordem alfabética considerando a tradução (Multi idioma)
      fields.sort(function (a, b) {
        let keyA = _this.applyTranslate(a.nome)
        let keyB = _this.applyTranslate(b.nome)

        // Compare the 2 values
        if (keyA < keyB) return -1
        if (keyA > keyB) return 1
        return 0
      })

      return fields
    },
    showBeginAtConfig () {
      if (this.task.taskFrequency.type && (this.task.taskFrequency.type.id === 1 || this.task.taskFrequency.type.id === 2 || this.task.taskFrequency.type.id === 4)) {
        return true
      }
      return false
    },
    showWeekdayConfig () {
      if (this.task.taskFrequency.type && this.task.taskFrequency.type.id === 2) {
        return true
      }
      return false
    },
    showMonthdayConfig () {
      if (this.task.taskFrequency.type && this.task.taskFrequency.type.id === 4) {
        return true
      }
      return false
    },
    showExpiresAtConfig () {
      if (this.task.taskFrequency.expiresType && this.task.taskFrequency.expiresType.id === 2) {
        return true
      }
      return false
    },
    showExpiresInConfig () {
      if (this.task.taskFrequency.expiresType && this.task.taskFrequency.expiresType.id === 3) {
        return true
      }
      return false
    },
    isSuperAdmin () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_SUPER_ADMIN') !== -1) {
        return true
      }
      return false
    }
  },
  watch: {
    user: function () {
      // CODIGO PARA REMOÇÂO DOS MENUS
    },
    isSuperAdmin: function () {
      // CODIGO PARA REMOÇÂO DOS MENUS
    }
  },
  methods: {
    groupToggle (group) {
      let _this = this
      group.isActive = !group.isActive

      if (!group.hasOwnProperty('preLoad')) {
        let fields = _this.getOptionsFiltersByGroup(group.id)

        for (let fieldIndex in fields) {
          let filter = fields[fieldIndex]

          if (filter.fieldA.tipo === 4 || filter.fieldA.tipo === 5 || filter.fieldA.tipo === 6 || filter.fieldA.tipo === 8) {
            _this.asyncFind(' ', filter.fieldA.codigo)
          }
        }
        // Disable pre load
        group.preLoad = true
      }
    },
    removeSequenciaTemplate (index) {
      this.task.templates.splice(index, 1)
    },
    addSequenciaTemplate () {
      this.task.templates.push(
        { idTemplate: null }
      )
    },
    rowClass (item, type) {
      let className = ''
      if (item.hasOwnProperty('Restricao_Envio') && item.Restricao_Envio) {
        className = 'inativo'
      }
      return className
    },
    onTemplateUpdated  (index, templateId) {
      let _this = this
      if (templateId) {
        let filters = {
          'per_page': 1000
        }

        MailService.getTemplates(filters).then(res => {
          _this.hasData = res.data.hasData
          _this.templateList = res.data.data

          let editedTemplate = res.data.data.find((item) => {
            return item.id_template === templateId
          })

          _this.task.templates[index].idTemplate = editedTemplate

          _this.showTemplateContent = false
        })
      } else {
        _this.showTemplateContent = false
      }
    },
    onTemplateCreated  (index, templateId) {
      let _this = this
      if (templateId) {
        let filters = {
          'per_page': 1000
        }

        MailService.getTemplates(filters).then(res => {
          _this.hasData = res.data.hasData
          _this.templateList = res.data.data

          let createdTemplate = res.data.data.find((item) => {
            return item.id_template === templateId
          })

          _this.task.templates[index].idTemplate = createdTemplate

          _this.showTemplateContent = false
        })
      } else {
        _this.showTemplateContent = false
      }
    },
    getOptionsFiltersByGroup (groupId) {
      let _this = this
      let optionsFilters = _this.getOptionsFilters

      optionsFilters = optionsFilters.filter(function (item) {
        let currentGroupId = (item.fieldA.id_task_field_grupo) ? item.fieldA.id_task_field_grupo.id : null

        // Se igual  ao grupo
        if (groupId === currentGroupId) {
          return true
        }

        return false
      })

      return optionsFilters
    },
    applyTranslate (val) {
      // Se não informado retorna vazio
      if (!val) {
        return ''
      }

      let translated = this.$i18n.t('taskCampaign.fields.' + val)

      if (translated.includes('taskCampaign.fields.')) {
        return val
      }

      return translated
    },
    onFileUploaded (fileDropzone, file, response) {
      let _this = this
      if (file.type === 'text/csv') {
        FileService.getCsvInfo(response.data).then((res) => {
          if (!res.data.columns.includes('Contato_email')) {
            _this.$alertSwal.modalAlert('', 'Arquivo inválido! <br/>Coluna <strong>Contato_email</strong> é obrigatória e<br/> não foi encontrada no arquivo.', 'error')
            fileDropzone.removeAllFiles()
          }
        })
      }
    },
    scrollToTop () {
      global.window.scrollTo(0, 0)
    },
    isIncludedContact: function (contact) {
      let _this = this
      let selected = _this.task.contacts.find((x) => {
        return x.id === contact.id
      })

      if (selected !== undefined) {
        return true
      }
      return false
    },
    toggleContactAllStatus (val) {
      if (val.length > 0) {
        let customer = val[0].cliente_id

        // Remove all from customer
        let contacts = this.task.contacts.filter(function (x) {
          return x.customer !== customer
        })
        this.task.contacts = contacts
      }

      if (val.length > 0) {
        if (val[0].status !== 2) {
          for (let i in val) {
            let item = val[i]
            let contact = {
              id: item.id,
              id_table_origin: item.id_table_origin,
              customer: item.cliente_id
            }
            this.task.contacts.push(contact)
          }
        }
      }
    },
    toggleContactStatus (val) {
      let contact = {
        id: val.id,
        id_table_origin: val.id_table_origin,
        customer: val.cliente_id
      }

      if (val.status === 1) {
        if (!this.isIncludedContact(contact)) {
          this.task.contacts.push(contact)
        }
      } else {
        if (this.isIncludedContact(contact)) {
          let index = this.task.contacts.findIndex(function (element) {
            return element.id === contact.id
          })
          // remove element from selected options
          this.task.contacts.splice(index, 1)
        }
      }
    },
    customerRemoved (val) {
      let contacts = this.task.contacts.filter(function (x) {
        return x.customer !== val
      })

      this.task.contacts = contacts
      if (this.contactList.length > 0) {
        if (this.contactList[0].cliente_id === val) {
          this.contactList = []
          this.activeCustomer = null
        }
      }
    },
    customerSelected (val) {
      let _this = this
      _this.isLoading = true
      _this.activeCustomer = val.data.id
      _this.contactList = []

      CustomerService.getCustomerContacts({ id: this.activeCustomer }, { 'per_page': 1000 }).then(res => {
        _this.contactList = res.data.data

        for (let i in _this.contactList) {
          let contact = _this.contactList[i]

          if (_this.isIncludedContact(contact)) {
            contact.status = 1
          }
        }

        _this.isLoading = false
      }).catch(() => {
        _this.isLoading = false
      })
    },
    // First Step "Avançar"
    toStep2 () {
      return new Promise((resolve, reject) => {
        let _this = this
        _this.$validator.validateAll('step-01').then((result) => {
          if (result && this.validWeekDay) {
            this.scrollToTop()
            resolve(result)
          }
          return reject(result)
        })
      })
    },
    toStep4 () {
      return new Promise((resolve, reject) => {
        let _this = this
        this.$validator.validateAll('step-03').then((result) => {
          if (result) {
            this.scrollToTop()
            _this.$refs.contactsTable.refresh()
            _this.$refs.contactsDuplicatedTable.refresh()
            // if csv file
            if (_this.task.files.length > 0) {
              _this.$refs.contactsCsvTable.refresh()
              resolve(true)
            } else {
              resolve(true)
            }
          }

          return reject(result)
        })
      })
    },
    // Second Step "Avançar"
    toStep3 () {
      return new Promise((resolve, reject) => {
        let _this = this
        _this.templateNewEditIndex = null

        // Reset Edit
        _this.templateNewEdit = {
          name: null,
          subject: null,
          files: [],
          content_html: '<p style="font-family:\'Helvetica Neue\',Helvetica,Arial,sans-serif;font-size:16px;line-height:24px;margin-bottom:16px;margin-top:16px;"></p>',
          showSignature: 1
        }
        _this.showTemplateContent = false

        _this.validateFieldFilters().then(res => {
          if (res) {
            this.scrollToTop()
            resolve(true)
          } else {
            resolve(false)
          }
        })
      })
    },
    validateFieldFilters () {
      let total = 0
      let valids = 0

      return new Promise((resolve, reject) => {
        let _this = this
        for (let i in _this.task.filters) {
          let filter = _this.task.filters[i]
          if (_this.isFieldFilter(filter)) {
            if (filter.fieldA.tipo === 3 && filter.value !== null) {
              total++
              this.$validator.verify(filter.value, 'date_format:dd/MM/yyyy').then(res => {
                if (!res.valid) {
                  filter.error = res.errors
                  _this.task.filters.splice(i, 1, filter)
                  resolve(false)
                }
                valids++
                if (valids === total) {
                  resolve(true)
                }
              })
            }
          }
        }

        if (total === 0) {
          resolve(true)
        }
      })
    },
    getActiveFilters (toSave) {
      let _this = this

      // Clone object to not change _this.task by reference
      let taskData = {}
      taskData = JSON.parse(JSON.stringify(_this.task))

      // Adjust filters
      let activeFilters = taskData.filters.filter(function (x) {
        return ((Array.isArray(x.value) && x.value.length > 0) && x.value !== null) || (!Array.isArray(x.value) && ((x.value !== null && x.value !== '') || x.fieldA.hasOwnProperty('id')))
      })

      for (let i in activeFilters) {
        let activeFilter = activeFilters[i]
        activeFilter.condition = (activeFilter.condition) ? activeFilter.condition.id : null
        activeFilter.operator = (activeFilter.operator) ? activeFilter.operator.id : null

        if (activeFilter.fieldA.tipo === 4 || activeFilter.fieldA.tipo === 5 || activeFilter.fieldA.tipo === 6 || activeFilter.fieldA.tipo === 8) {
          activeFilter.condition = 6
          activeFilter.operator = 1
        }

        activeFilter.sortOrder = i

        if (!Array.isArray(activeFilter.value) && (!activeFilter.fieldB || !activeFilter.fieldB.hasOwnProperty('id'))) {
          let value = []
          value.push({
            value: activeFilter.value
          })
          activeFilter.value = value
        }

        if (toSave) {
          activeFilter.fieldA = activeFilter.fieldA.id
          if (activeFilter.fieldB) {
            activeFilter.fieldB = activeFilter.fieldB.id
          }
        }

        if (Array.isArray(activeFilter.options) || activeFilter.options == null) {
          delete activeFilter.options
        }
      }

      taskData.filters = activeFilters

      return taskData.filters
    },
    addFilter (index) {
      // Adicionar após
      index++
      let _this = this
      // Added empty field filter on began
      let filter = {}
      filter.fieldA = {
        tipo: 1
      }
      filter.value = null
      filter.condition = null
      filter.options = []

      _this.task.filters.splice(index, 0, filter)
    },
    removeFilter (index) {
      let _this = this
      _this.task.filters.splice(index, 1)

      // Se for o último adiciona um vazio
      if (_this.getOptionsFilters.length === _this.task.filters.length) {
        let filter = {}
        filter.fieldA = {
          tipo: 1
        }
        filter.value = null
        filter.condition = null
        filter.options = []
        _this.task.filters.push(filter)
      }
    },
    isFieldFilter (filter) {
      return filter.fieldA.tipo !== 4 && filter.fieldA.tipo !== 5 && filter.fieldA.tipo !== 6 && filter.fieldA.tipo !== 8
    },
    // Download example file
    async handleDownloadExample () {
      this.isLoading = true
      let fileName = 'contatos.csv'
      let _this = this

      TaskService.getTaskCampaignFile().then(res => {
        const url = global.window.URL.createObjectURL(new Blob([res.data]))
        const link = global.document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        global.document.body.appendChild(link)
        link.click()
        _this.isLoading = false
      }).catch(() => {
        _this.isLoading = false
      })
    },
    // eslint-disable-next-line camelcase
    customLabel ({ id, nome }) {
      // eslint-disable-next-line camelcase
      return `${id} – ${nome}`
    },
    mailCustomLabel ({ email }) {
      // eslint-disable-next-line camelcase
      return `${email}`
    },
    limitText (count) {
      return `de ${count} opções`
    },
    onInput (query, fieldId) {
      let _this = this
      _this.isLoadingSearch = true
      _this.isLoadingSearch = false
    },
    asyncFind (query, fieldId) {
      let _this = this

      let filterField = _this.task.filters.find((x) => {
        return x.fieldA.codigo === fieldId
      })

      if (query) {
        _this.isLoadingSearch = true

        let filter = {
          searchTerm: query,
          field: fieldId
        }

        MailService.getVariableSearch(filter).then(res => {
          filterField.options = []
          filterField.options = res.data.data
          _this.isLoadingSearch = false
        })
      } else {
        filterField.options = []
      }
    },
    showNumber (number, thousandsSep, defaultValue) {
      return util.showNumber(number, thousandsSep, defaultValue)
    },
    showCurrency (number, thousandsSep, defaultValue) {
      return util.numberFormat(number, 2, thousandsSep, defaultValue)
    },
    onSelectTier (val) {
      this.task.tier = val
    },
    onSelectTipoDisparador (val) {
      this.task.idTipoDisparador = val
    },
    onSelectRecurrence (val) {
      this.task.taskFrequency.type = val
    },
    onSelectExpiresType (val) {
      if (val.id !== 2) {
        this.task.taskFrequency.expiresAt = null
      } else if (val.id !== 3) {
        this.task.taskFrequency.expiresIn = null
      }
    },
    onSelectStrategy (val) {
      if (val.id === 2) {
        this.task.showShipowner = null
      }

      this.task.strategy = val
    },
    mountLink (itemName, object) {
      return this.$router.resolve({ name: itemName, params: { id_schedule: object.id } }).href
    },
    setEmployee (value) {
      this.task.employee = value
    },
    /**
     * Triggered when the customer search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    customerFetchOptions (search, loading) {
      loading(true)
      let filters = {
        'searchTerm': search
      }

      CustomerService.getCustomers(filters).then(res => {
        this.hasData = res.data.hasData
        this.customerList = res.data.data
        loading(false)
      })
    },
    employeeFetchOptions (search, loading) {
      loading(true)
      let filters = {
        'searchTerm': search
      }

      UserService.getUsers(filters).then(res => {
        this.hasData = res.data.hasData
        this.employeeList = res.data.data
        loading(false)
      })
    },
    /**
     * Triggered when the customer search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    originDestinationFetchOptions (search, loading) {
      loading(true)
      let filters = {
        'searchTerm': search
      }

      OriginDestinationService.getOriginsDestinations(filters).then(res => {
        this.hasData = res.data.hasData
        this.originsDestinationsList = res.data.data
        loading(false)
      })
    },
    /**
     * Triggered when the commoditie search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    commoditiesFetchOptions (search, loading) {
      loading(true)
      let filters = {
        'searchTerm': search,
        'per_page': 1000
      }

      CommoditieService.getCommodities(filters).then(res => {
        this.hasData = res.data.hasData
        this.commoditiesList = res.data.data
        loading(false)
      })
    },
    /**
     * Triggered when the template search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    templateFetchOptions (search, loading) {
      loading(true)
      let filters = {
        'searchTerm': search,
        'per_page': 1000
      }

      MailService.getTemplates(filters).then(res => {
        this.hasData = res.data.hasData
        this.templateList = res.data.data
        loading(false)
      })
    },
    /**
     * Triggered when the template search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    tipoDisparadorFetchOptions (search, loading) {
      loading(true)
      let filters = {
        'searchTerm': search,
        'per_page': 1000
      }

      TaskCampaignTypeService.getTaskCampaignTypeList(filters).then(res => {
        this.hasData = res.data.hasData
        this.tipoDisparadorList = res.data.data
        loading(false)
      })
    },
    /**
     * Triggered when the transport companies search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    transportCompanyFetchOptions (search, loading) {
      loading(true)
      let filters = {
        'searchTerm': search
      }

      TransportCompanyService.getTransportCompanies(filters).then(res => {
        this.hasData = res.data.hasData
        this.transportCompanyList = res.data.data
        loading(false)
      })
    },
    onSave () {
      let _this = this

      _this.isLoading = true

      // Clone object to not change _this.task by reference
      let taskData = {}
      taskData = JSON.parse(JSON.stringify(_this.task))

      delete taskData.idTemplate

      taskData.filters = _this.getActiveFilters(true)
      // End filters

      let formatedTemplates = []

      formatedTemplates = taskData.templates.map((nome, i) => {
        return {
          idTemplate: nome.idTemplate.id_template,
          sortOrder: i + 1
        }
      })

      taskData.templates = formatedTemplates

      // Tipo Disparador
      taskData.idTipoDisparador = taskData.idTipoDisparador.id

      taskData.taskFrequency.type = taskData.taskFrequency.type.id

      if (taskData.taskFrequency.expiresType.id === 2) {
        let dayParse = _this.dayParse
        taskData.taskFrequency.expiresAt = dayjs(taskData.taskFrequency.expiresAt, dayParse).isValid() ? (dayjs(taskData.taskFrequency.expiresAt, dayParse).hour(0).minute(0).second(0)).toISOString() : null
      }

      // Remove Expires Type
      delete taskData.taskFrequency.expiresType

      taskData.taskFrequency.weekdays = _this.weekDayList.filter(function (x) {
        return x.active === true
      }).map(x => (x.id)).join(',')

      // Status
      taskData.status = parseInt(taskData.status)

      if (taskData.status !== 1) {
        taskData.status = 2
      }

      // GroupMails
      taskData.groupMails = parseInt(taskData.groupMails)

      if (taskData.groupMails !== 1) {
        taskData.groupMails = 2
      }

      // Format CancelSendList

      taskData.cancelSendList = taskData.cancelSendList.map(item => {
        let formated = {}
        formated.Contato_id = item.Contato_id

        return formated
      })

      let taskId = this.$route.params.id_schedule

      if (taskId) {
        TaskService.editTaskCampaign(taskId, taskData).then(response => {
          _this.$router.push({ name: 'SchedulesTaskCampaingIndex', params: { ignoreOnLeaveConfirm: '1' } })
          _this.isLoading = false
        }).catch(() => {
          _this.isLoading = false
        })
      } else {
        TaskService.newTaskCampaign(taskData).then(response => {
          _this.$router.push({ name: 'SchedulesTaskCampaingIndex', params: { ignoreOnLeaveConfirm: '1' } })
          _this.isLoading = false
        }).catch(() => {
          _this.isLoading = false
        })
      }
    },
    onSelectTemplate (val) {
      this.templateNewEditIndex = null

      this.templateNewEdit = {
        name: null,
        subject: null,
        files: [],
        content_html: '<p style="font-family:\'Helvetica Neue\',Helvetica,Arial,sans-serif;font-size:16px;line-height:24px;margin-bottom:16px;margin-top:16px;"></p>',
        showSignature: 1
      }
      this.showTemplateContent = false
      this.task.idTemplate = val
    },
    addNewTemplate (index) {
      let _this = this
      _this.task.idTemplate = null

      _this.templateNewEditIndex = index
      _this.templateNewEdit = {
        name: null,
        subject: null,
        files: [],
        content_html: '<p style="font-family:\'Helvetica Neue\',Helvetica,Arial,sans-serif;font-size:16px;line-height:24px;margin-bottom:16px;margin-top:16px;"></p>',
        showSignature: 1
      }
      _this.showTemplateContent = true

      setTimeout(function () {
        _this.$util.scrollToFirst('template-content-edit')
      }, 800)
    },
    editTemplate (index, idTemplate) {
      let _this = this
      _this.isLoading = true
      _this.showTemplateContent = true
      MailService.getOneTemplate(idTemplate).then(res => {
        _this.templateNewEditIndex = index
        _this.templateNewEdit = {
          id_template: res.data.data.id_template,
          name: res.data.data.name,
          subject: res.data.data.subject,
          files: res.data.data.files,
          content_html: res.data.data.content_html,
          showSignature: res.data.data.show_signature
        }
        _this.isLoading = false
        _this.$util.scrollToFirst('template-content-edit')
      })
    },
    onNewTemplateClosed (event) {
      let _this = this
      _this.isLoading = true
      MailService.getTemplates().then(res => {
        _this.templateList = res.data.data
        _this.task.idTemplate = null
        _this.isLoading = false
      })
    },
    showContactData (data, index) {
      let _this = this

      _this.$modal.show(
        MessagePreviewModal,
        {
          templates: _this.task.templates,
          contact: data
        },
        {
          draggable: false,
          adaptive: true,
          scrollable: true,
          clickToClose: false,
          width: '650px',
          height: 'auto'
        },
        {
          'before-open': _this.beforeOpen,
          'before-close': _this.beforeClose
        }
      )
    },
    beforeOpen (event) {
    },
    beforeClose (event, a, b) {
      // event.cancel()
    }
  }
}
</script>

<style scoped>
  .swal2-popup {
    width: inherit !important;
  }

  .card-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md-form-control {
    height: 30px;
  }

  .accordion {
    border-bottom: 1px solid #ccc;
  }

  .accordion-header {
    border: 1px solid #ccc;
    border-bottom: 0;
    padding: 0.5rem;
    font-family: "Pluto Sans Medium";
  }

  .accordion-header:hover, .accordion-header.active {
    background-color: #877953;
    color: #f1f1f1;
  }

  .accordion-content {
    padding: 0.5rem;
    padding-bottom: 1rem;
    border: 1px solid #ccc;
    border-bottom: 0;
    border-top: 0;
  }

  .sortable {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #ccc;
  }

  .sortable:first-child {
    border-top: 1px solid #ccc;
  }

  .sortable .sort-icon {
    position: absolute;
    top: 5px;
    right: 5px;
  }

  .sortable-icon {
    height: 20px;
    width: 20px;
    position: relative;
    top: 10px;
    right: 16px;
    text-align: center;
  }

  .sortable-remove {
    font-size: 0.7vw;
    bottom: 0;
    right: 0;
    /*display: none;*/
  }

  .sortable:hover .sortable-remove {
    display: block;
  }
</style>
