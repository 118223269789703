<template>
  <div class="form-group" :class="rootClassName">
    <label v-for="(item, index) in listItems" :key="`div_${index}`"  :class="getInputClassName" >
      <input v-if="!isView" :type="type" :name="name" :checked="item.value === value"  :ref="refName" :id="`input_${currentKey}`" :value="item.value" @input="handleInput($event, item.value)" @change="handleInput($event, item.value)" @blur="$emit('blur')" :disabled="disabled" />
      <span v-if="!isView" class="state" :class="stateClassName"></span>
      <div class="label">{{ item.text }}</div>
      <div v-if="isView" class="no-margim no-padding">
        {{ (item.value === value) ? 'Sim' : 'Não' }}
      </div>
    </label>
    <transition name="slide" mode="out-in">
      <small v-if="error" class="royalc-error-field">{{ error }}</small>
    </transition>
  </div>
</template>

<script>

export default {
  inheritAttrs: false,
  name: 'CustonSwitch',
  data () {
    return {
      refName: this.refInput || 'field_' + Math.random().toString().substr(2),
      currentKey: ''
    }
  },
  mounted () {
    this.currentKey = this.getKey()
  },
  $_veeValidate: {
    // value getter
    value () {
      return this.$el.value
    },
    // name getter
    name () {
      return this.name
    }
  },
  props: {
    isView: {
      type: Boolean,
      required: false,
      default: false
    },
    listItems: {
      type: Array,
      required: true
    },
    refInput: String,
    name: String,
    value: {
      type: null,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: null,
      required: false
    },
    type: {
      type: String,
      default: 'checkbox'
    },
    rootClassName: {
      type: String,
      default: 'text-right col-sm-2 mt-2 mb-6'
    },
    inputClassName: {
      type: String,
      default: 'ui-switch no-margin small switch-solid switch-warning'
    },
    stateClassName: {
      type: String
    }
  },
  computed: {
    getInputClassName () {
      if (this.disabled) {
        return this.inputClassName + ' disabled'
      }

      return this.inputClassName
    }
  },
  methods: {
    handleInput (e, value) {
      if (e.target.checked) {
        this.$emit('input', value)
      } else {
        this.$emit('input', null)
      }
    },
    update () {
    },
    updated () {
    },
    getKey () {
      return Math.random().toString().substr(2)
    }
  }
}
</script>

<style lang="scss" scoped>
  $pretty--color-default: #84754E !default;
  @import 'pretty-checkbox/src/pretty-checkbox.scss';

  .slide-enter-active,
  .slide-leave-active {
    transition: all 0.3s;
  }
  .slide-enter,
  .slide-leave-to {
    opacity: 0;
  }

  div.label {
    line-height: 16px;
    margin-left: 5px;
  }

  .disabled {
    cursor: not-allowed;
    color: #83dadb;
  }
</style>
